<template>
  <router-link v-if="hasTo" :to="to" @click.native="onClick">
    <span v-if="title">{{ title }}</span>
    <slot v-else name="default" />
  </router-link>
  <a v-else-if="hasLink" :href="link" :target="target" @click="onClick">
    <span v-if="title">{{ title }}</span>
    <slot v-else name="default" />
  </a>
  <a v-else @click="onClick">
    <span v-if="title">{{ title }}</span>
    <slot v-else name="default" />
  </a>
</template>

<script>
import _ from 'underscore';

export default {
  props: {
    // Text for link tag
    title: {
      required: false,
      type: String,
      default: null,
    },
    to: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    link: {
      required: false,
      type: String,
      default: () => null,
    },
    target: {
      required: false,
      type: String,
      default: '_self',
    },
  },

  computed: {
    hasTo() {
      return this.to !== null && _.isObject(this.to) && !_.isEmpty(this.to);
    },
    hasLink() {
      return this.link !== null && !_.isEmpty(this.link);
    },
  },

  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style></style>
